import API from "@/utils/request";
import { ROUTES_GLOBAL } from "../paths";
import { AxiosInstance } from "axios";
import { SECTOR_ENDPOINTS } from "./endpoint";
import { CreateSectorEntity, CreateSectorHourEntity } from "@/types/request/location";
import { GetSectors } from "@/types/response/locations";

export class SectorApi {
  private request = new API(ROUTES_GLOBAL.SECTOR);
  private api: AxiosInstance = this.request.getInstance();

  async create(data: CreateSectorEntity){
    await this.api.post(SECTOR_ENDPOINTS.CREATED,data)
  }

  async updateSector(id: string, hours : CreateSectorHourEntity[]){
    await this.api.put(SECTOR_ENDPOINTS.UPDATE_HOURS.replace(":id",id),{hours})
  }

  async get(){
    const {data} = await this.api.get<GetSectors[]>(SECTOR_ENDPOINTS.GET);
    return data;
  }
}
