
import { formatCurrency, getErrorCatch } from '@/utils';
import { Vue, Options } from 'vue-class-component';
import { ScheduleApi } from '@/api/schedule'
import { GetMyScheduleAdminResponse } from "@/types/response/schedule";
import { useAuthStore } from '@/store/modules/auth';
import { formatDateStringComplete } from '@/filters/formatDate';
import { DateTime } from 'luxon';
import ModalCommon from '@/components/common/ModalCommon.vue';
import { GetPlaceIdRes, VehicleListMapping,DriverVehicle } from '@/types/response/locations';

@Options({
    name: 'AirportToday',
    components: {
        ModalCommon
    }
})
export default class AirportToday extends Vue {
    formatDateStringComplete = formatDateStringComplete;
    loading = false;
    private authStore = useAuthStore();
    date = new Date();
    private apiSchedule = new ScheduleApi()
    public schedules: GetMyScheduleAdminResponse[] = []
    public tripLoading: string[] = []
    public scheduleSelected: GetMyScheduleAdminResponse | null = null;
    public isVisibleSelectedDrivers = false;
    public loadingSetDriver = false;
    public vehicles: VehicleListMapping[] = []

    formatCurrency= formatCurrency

    mounted(): void {
        this.getScheduleAdmin(new Date())
    }

    setLoadingSchedule(id: string, loading = false){
        this.schedules = this.schedules.map((item) => {
            if(id!== item._id){
                return item
            }
            return {
                ...item,
                loading
            }
        })
    }

    async setScheduleSelected(schedule: GetMyScheduleAdminResponse) {
        this.scheduleSelected = schedule;
        this.setLoadingSchedule(schedule._id, true)
        try {
            this.vehicles = []
            const vehiclesUnMapped = await this.apiSchedule.listDriversAvailable(schedule._id)
            this.vehicles = this.mappedVehicle(vehiclesUnMapped)
            this.isVisibleSelectedDrivers = true;
        } catch (error) {
            getErrorCatch(error)
        }
        this.setLoadingSchedule(schedule._id, false)
    }

    mappedVehicle(vehiclesEntry: GetPlaceIdRes[]) {
        const vehicles: VehicleListMapping[] = []
        for (let index = 0; index < vehiclesEntry.length; index++) {
            const element = vehiclesEntry[index];
            for (let index = 0; index < element.vehicles.length; index++) {
                const elementVehicle = element.vehicles[index];
                vehicles.push({
                    _id: element._id,
                    bags: elementVehicle._id.bags,
                    seatings: elementVehicle._id.seatings,
                    duration: element.duration,
                    nameType: element.typeVehicle.name,
                    price: element.price,
                    driver: elementVehicle.driver as DriverVehicle,
                    patent: elementVehicle.patent,
                    idVehicle: elementVehicle.idVehicle
                })
            }
        }
        return vehicles
    }

    closeModalVisibleSelectedDriver() {
        this.isVisibleSelectedDrivers = false;
        this.scheduleSelected = null
    }

    updateDriver() {
        console.log("actuqalizando")
    }

    getTotalPassengers(schedule: GetMyScheduleAdminResponse){
        if(schedule && schedule.trip && schedule.trip.seatingsInfo){
            const {trip: {seatingsInfo : {adults,babys,children}}} = schedule
            return adults + babys + children
        }
        return 0
    }

    async getScheduleAdmin(date: Date) {
        if(!this.authStore.user){
            return;
        }
        this.loading = true;
        try {
            const timeFinal = DateTime.fromJSDate(date).set({ hour : 12, minute: 0, second: 0 }).setZone('America/Santiago', { keepLocalTime: true }).toJSDate();
            if(this.roleUser == "DRIVER"){
                this.schedules = await this.apiSchedule.get(timeFinal.toISOString());
            }else{
                this.schedules = await this.apiSchedule.getAdmin(timeFinal.toISOString());
            }
            
        } catch (error) {
            getErrorCatch(error)
        }
        this.loading = false;
    }

    changeDate(value: string) {
        this.getScheduleAdmin(new Date(value))
    }
    get roleUser() {
        return this.authStore.user?.role;
    }

    getDestination({ destination }: GetMyScheduleAdminResponse) {
        if (destination.split(",").length > 0) {
            if (destination.split(",").length > 1) {
                return destination.split(",")[0] + " - " + destination.split(",")[1]
            }
            return destination.split(",")[0]
        }
        return ''
    }

    getDate({ startTime, endTime }: GetMyScheduleAdminResponse) {
        const start = DateTime.fromISO(startTime, { zone: 'utc' }).setZone('America/Santiago');
        const end = DateTime.fromISO(endTime, { zone: 'utc' }).setZone('America/Santiago');
        
        if (start.hasSame(end, 'day')) {
            return `${start.toFormat('yyyy-MM-dd')}<br>${start.toFormat('HH:mm')} - ${end.toFormat('HH:mm')}`;
        } else {
            return `${start.toFormat('yyyy-MM-dd')}<br>${start.toFormat('HH:mm')} - <br>${end.toFormat('yyyy-MM-dd')}<br>${end.toFormat('HH:mm')}`;
        }
    }

    getAddressComplete({ trip }: GetMyScheduleAdminResponse){
        if(trip.additionalInfoAddress){
            const {addressText,houseNumber} = trip.additionalInfoAddress
            const listAddressText = addressText.split(",");
            return `${listAddressText[0]} ${houseNumber !== '' ? "#": ""}${houseNumber}`
        }
        return ''
    }

    getCommune({ trip }: GetMyScheduleAdminResponse){
        if(trip.additionalInfoAddress){
            const {addressText,houseNumber,commune} = trip.additionalInfoAddress
            const addressTextList = addressText.split(",");
            if(addressTextList.length<2){
                return commune
            }
            const communeEntry = addressTextList[1];
            return communeEntry == commune ? communeEntry : commune
        }
        return ''
    }

    getTypeTrip({ destination }: GetMyScheduleAdminResponse) {
        return destination == 'SCL - Arturo Merino Benitez' ? 'Hacia el aeropuerto' : 'Desde el aeropuerto'
    }

    getTitle({ destination, origin }: GetMyScheduleAdminResponse) {
        return destination == 'SCL - Arturo Merino Benitez' ? origin.split(",").splice(0, 2).join(" - ") : destination.split(",").splice(0, 2).join(" - ")
    }

    getStatus(entryStatus: string) {
        const status: Record<string, string> = {
            ON_HOLD: "En espera",
            IN_PROGRESS: "En progreso",
            COMPLETED: "Finalizado",
        };
        return status[entryStatus] || "";
    }

    getColorIcon(status: string) {
        let color = "black";
        switch (status) {
            case 'ON_HOLD':
                color = "grey";
                break;
            case 'IN_PROGRESS':
                color = "green";
                break;
            case 'FINALIZED':
                color = "primary";
                break;
        }
        return `${color} darken-2`;
    }

    getColorTextStatus(status: string) {
        const colorIcon = this.getColorIcon(status);
        const colors = colorIcon.split(" ");
        if (colors.length == 1) return `text-${colors[0]}`;
        return `text-${colors[0]} text-${colors[1]}`;
    }

    getIcon(status: string) {
        switch (status) {
            case 'ON_HOLD':
                return "mdi-bus-clock";
            case 'IN_PROGRESS':
                return "mdi-bus-side";
            case 'FINALIZED':
                return "mdi-handshake";
        }
        return "mdi-account";
    }

    setLoading(id: string, value: boolean) {
        if (value) {
            this.tripLoading.push(id)
        } else {
            this.tripLoading = this.tripLoading.filter((item) => item !== id)
        }
    }


    async startTrip({ _id }: GetMyScheduleAdminResponse) {
        this.setLoading(_id, true)
        try {
            await this.apiSchedule.startTrip(_id)
            this.schedules = this.schedules.map((item) => {
                if (item._id === _id) {
                    item.status = 'IN_PROGRESS'
                }
                return item
            })
        } catch (error) {
            getErrorCatch(error)
        }
        this.setLoading(_id, false)
    }

    async finishTrip({ _id }: GetMyScheduleAdminResponse) {
        this.setLoading(_id, true)
        try {
            await this.apiSchedule.endTrip(_id)
            this.schedules = this.schedules.map((item) => {
                if (item._id === _id) {
                    item.status = 'COMPLETED'
                }
                return item
            })
        } catch (error) {
            getErrorCatch(error)
        }
        this.setLoading(_id, false)
    }

    async resetTrip({ _id }: GetMyScheduleAdminResponse) {
        this.setLoading(_id, true)
        try {
            await this.apiSchedule.resetTrip(_id)
            this.schedules = this.schedules.map((item) => {
                if (item._id === _id) {
                    item.status = 'ON_HOLD'
                }
                return item
            })
        } catch (error) {
            getErrorCatch(error)
        }
        this.setLoading(_id, false)
    }
    getIconPrice(price: number) {
        if(!this.scheduleSelected){
            return 'mdi-bowl-outline'
        }
        if (price < this.scheduleSelected.trip.totalPriceFinal) {
            return 'mdi-arrow-down';
        } else if (price > this.scheduleSelected.trip.totalPriceFinal) {
            return 'mdi-arrow-up';
        } else {
            return 'mdi-minus';
        }
    }

    async setDriver(driver:string, vehicle: string){
        if(!this.scheduleSelected){
            return
        }
        this.loadingSetDriver = true;
        try {
            const place = await this.apiSchedule.updateDriverVehicleSchedule(this.scheduleSelected._id, driver, vehicle) as any;
            if(this.scheduleSelected){
                this.scheduleSelected.driver = place.driver;
                this.scheduleSelected.vehicle = place.vehicle;
            }
            await this.setScheduleSelected(this.scheduleSelected);
        } catch (error) {
            getErrorCatch(error)
        }
        this.loadingSetDriver = false;
    }
}

