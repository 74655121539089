
import { formatDateSimple, convertTo12HourFormatV2 } from '@/filters/formatDate';
import { useAirportBookingStore } from '@/store/modules/airportBooking';
import { GetHourPlaceId, GetHourPlaceVehicle } from '@/types/response/locations';
import { CreateTripAirport } from '@/types/request/trip';
import { Options, Vue } from 'vue-class-component';
import { formatCurrency, getErrorCatch } from '@/utils';
import { TripAirportApi } from '@/api/trip/aiport'
import { CreateTripFreeResponse, CreateTripResponse } from '@/types/response/trip';
import HeaderCommonBooking from './HeaderCommonBooking.vue';
import PaymentRouteAirport from './Payment/PaymentRouteAirport.vue';
import PaymentScheduleAirport from './Payment/PaymentScheduleAirport.vue';
import PaymentPriceAirport from './Payment/PaymentPriceAirport.vue';
import PaymentCouponAirport from './Payment/PaymentCouponAirport.vue';
import { GetCouponNameResponse } from '@/types/response/coupon';
import PaymentMethodsAirport from './Payment/PaymentMethodsAirport.vue';


@Options({
    name: 'DetailSectorPayment',
    components: {
        HeaderCommonBooking,
        PaymentRouteAirport,
        PaymentScheduleAirport,
        PaymentPriceAirport,
        PaymentCouponAirport,
        PaymentMethodsAirport
    }
})
export default class DetailSectorPayment extends Vue {
    public apiAirport = new TripAirportApi()
    public airportStore = useAirportBookingStore();
    public formatDateSimple = formatDateSimple;
    public formatCurrency = formatCurrency;
    public convertTo12HourFormatV2 = convertTo12HourFormatV2;
    public hours: string[] = []
    public loadingCreated = false;
    public couponResponse :GetCouponNameResponse | null= null;
    public discount = 0;
    public vehiclesAvailable: GetHourPlaceVehicle[] = [];
    public couponName = '';

    get step() {
        return this.airportStore.step
    }

    get total(){
        if(this.airportStore.vehicleAiportSelected){
            return this.airportStore.vehicleAiportSelected.price
        }
        return 0
    }

    get place() {
        return this.airportStore.placeInfo
    }

    get dataForm() {
        return this.airportStore.form
    }

    get vehicleAiportSelected() {
        return this.airportStore.vehicleAiportSelected
    }

    get ownerData(){
        return this.airportStore.formDataUser
    }

    get typeTripSelected(){
        return this.airportStore.typeTripSelected
    }

    get form(){
        return this.airportStore.form
    }

    get seatingsInfo(){
        return this.airportStore.seatingsInfo
    }

    get additionalInfoAddres(){
        return this.airportStore.addressInfo
    }

    searchCoupon({ couponResponse, discount, name }: { couponResponse: GetCouponNameResponse | null, discount: number, name: string }) {
        this.couponResponse = couponResponse;
        this.discount = discount
        this.couponName = name
    } 

    toBack() {
        this.airportStore.setStep('data-user');
    }

    async toPay(value : "oneclick" | "webpay") {
        if (!this.vehicleAiportSelected) {
            return;
        }
        this.loadingCreated = true
        try {
            const { date, destination } = this.dataForm
            const data: CreateTripAirport = {
                bags: this.vehicleAiportSelected.bags,
                date: date.toISOString(),
                hourIdSector: this.vehicleAiportSelected._id,
                placeid: destination as string,
                seatings: this.vehicleAiportSelected.seatings,
                paymentEntry: {
                    typePayment: value,
                    coupon: this.couponName
                },
                owner: this.ownerData,
                typeTripSelected : this.typeTripSelected,
                seatingsQuery : parseInt(this.form.seatings as string),
                seatingsInfo: {
                    ...this.seatingsInfo
                },
                additionalInfoAddress : {
                    ...this.additionalInfoAddres
                }
            }
            const responseCreateTrip = await this.apiAirport.createTripAirport(data)
            if (value == 'oneclick') {
                if ((responseCreateTrip as CreateTripFreeResponse).code) {
                    this.$router.push('/airport?confirm=' + (responseCreateTrip as CreateTripFreeResponse).code)
                } else {
                    throw new Error('Error al crear el viaje')
                }
            } else if (value == 'webpay') {
                if ((responseCreateTrip as CreateTripResponse).token) {
                    window.location.href = `${(responseCreateTrip as CreateTripResponse).url}?token=${(responseCreateTrip as CreateTripResponse).token}`
                } else if((responseCreateTrip as CreateTripFreeResponse).code){
                    this.$router.push('/airport?confirm=' + (responseCreateTrip as CreateTripFreeResponse).code)
                }else {
                    throw new Error('Error al crear el viaje')
                }
            }
            this.airportStore.$reset()
        } catch (error) {
            getErrorCatch(error)
        }
        this.loadingCreated = false
    }
}
