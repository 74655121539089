import { RouteLocationNormalized } from "vue-router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import router from "./router";
import { useAuthStore } from "./store/modules/auth";
import { roles } from "./types/common";
import { ParameterApi } from "./api/parameter";
import { useMainStore } from "./store/modules/main";

NProgress.configure({ showSpinner: true });

const whiteList = [
  "/login",
  "/",
  "/registro",
  "/404",
  "/terminos-condiciones",
  "/politica-privacidad",
  "/booking",
  "/registrov2",
  "/recuperar-cuenta",
  "/contacto",
  "/punto-subida",
  "/rutas-horarios",
  "/donde-va-mi-van",
  "/airport"
];

const driverRoutes = ["/aeropuerto-hoy","/donde-va-mi-van"];
const nameRoutesUser = ["GPSPage", "Profile"];

/* eslint-disable */

router.beforeEach(
  async (
    to: RouteLocationNormalized,
    _: RouteLocationNormalized,
    next: any
  ) => {
    if (to.path == "/404") {
      next();
      return;
    }
    NProgress.start();
    const store = useAuthStore();
    const mainStore = useMainStore();
    const api = new ParameterApi();

    try {
      const statusOn = await api.getStatuServer();
      if (!statusOn) {
        if (to.path == "/login") {
          next();
        } else if (to.path !== "/maintenance") {
          if (store.token) {
            await store.getUser();
          }
          if (store.isLogged && store.user) {
            const user = store.user;
            if (user.role !== roles.ADMIN && user.role !== roles.DRIVER) {
              next("/maintenance");
            } else {
              next();
            }
          } else {
            next("/maintenance");
          }
        } else {
          next();
        }
        return;
      } else {
        if (to.path == "/maintenance") {
          next("/");
          return;
        }
      }
    } catch (error) {
      next("/maintenance");
      return;
    }
      try {
        const statusOnAirport = await api.getStatusAirport();
        mainStore.setStatusAirport(statusOnAirport);
        if(to.path == "/airport" && !to.query.confirm){
          if(!statusOnAirport){
            next("/")
          }else{
            next()
          }
        }
      } catch (error) {
        next("/");
        return;
      }
    if (store.token) {
      const path = to.path;
      if (path == "/404") {
        next();
        return;
      }
      if (store.isLogged && store.user) {
        const user = store.user;
        if (to.path === "/dashboard") {
          NProgress.done();
          next();
          return;
        }
        switch (user.role) {
          case roles.DRIVER:
            if(to.path.split("/").length == 0){
              next(driverRoutes[0]);
              NProgress.done();
              return;
            }
            const pathReal = to.path.split("/")[1];
            if (!driverRoutes.includes("/"+pathReal)) {
              next(driverRoutes[0]);
            } else {
              next();
            }
            NProgress.done();
            return;
            break;
          case roles.CUSTOMER:
            if (
              !whiteList.includes(to.path) &&
              !nameRoutesUser.includes(to.name as string)
            ) {
              next("/dashboard");
            } else {
              next();
            }
            return;
          default:
            break;
        }
        next();
      } else {
        try {
          await store.getUser();
          next({ ...to, replace: true });
        } catch (error) {
          store.resetToken();
          next(`/login?redirect=${to.path}`);
          NProgress.done();
        }
      }
    } else {
      if(to.name == 'GPSPage'){
        next();
        NProgress.done();
      }else if (whiteList.indexOf(to.path) !== -1) {
        next();
      } else {
        next(`/login?redirect=${to.path}`);
        NProgress.done();
      }
    }
  }
);

router.afterEach((to: RouteLocationNormalized) => {
  NProgress.done();
});
