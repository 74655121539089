import API from "@/utils/request";
import { ROUTES_GLOBAL } from "../paths";
import { AxiosInstance } from "axios";
import { PARAMETERS_ENDPOINTS } from "./endpoints";


export interface ParameterKey {
  key: string;
  value: string;
}

export class ParameterApi {
  private request = new API(ROUTES_GLOBAL.PARAMETER);
  private api: AxiosInstance = this.request.getInstance();

  async updateKey(key: string, value: string) {
    await this.api.put(PARAMETERS_ENDPOINTS.UPDATE_KEY + key, { value });
  }

  async getStatuServer() {
    const URL = "/status";
    const { data } = await this.api.get<ParameterKey>(URL);
    return data.value == "1";
  }

  async getStatusAirport(){
    const URL = "/status-airport";
    const { data } = await this.api.get<ParameterKey>(URL);
    return data.value == "1";
  }
}
