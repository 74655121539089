
import { SectorApi } from '@/api/sector';
import ModalCommon from '@/components/common/ModalCommon.vue';
import { CreateSectorHourEntity, CreateSectorHourVehicleEntity } from '@/types/request/location';
import { GetSectors, Hour } from '@/types/response/locations';
import { formatCurrency, getErrorCatch, requiredField, validateNumber } from '@/utils';
import { Options, Vue } from 'vue-class-component';
import { Header } from 'vue3-easy-data-table';
import { DateTime } from 'luxon';
import { GetTypesVehicleResponse } from '@/types/response/vehicle';
import { VehicleAPI } from '@/api/vehicles';
import { FormVuetify } from '@/types/common/vuetify';

@Options({
    name: 'SectorsView',
    components: {
        ModalCommon
    }
})
export default class SectorsView extends Vue {
    public visibleCreateSector = false;
    public apiSector = new SectorApi()
    private vehicleApi = new VehicleAPI();
    public  isLoadingCreated= false;
    public loadingTable = false
    public items: GetSectors[] = [];
    public isVisibleEditSector = false;
    public messageLoading= "";
    public isVisibleDetail = false;
    public sectorHourSelected: Hour | null = null;
    public sectorSelected: GetSectors | null = null
    public formatCurrency = formatCurrency;
    public requiredField= requiredField;
    public validateNumber = validateNumber
    public timeout= 0;
    public dataAddVehicle: CreateSectorHourVehicleEntity = {
        duration: null,
        price: null,
        typeVehicle: null
    }

    public sectorHour: string | null = null
    public sectorMessageError = "";
    public sectorHourIsError = false
    public sectorHourTypeBlocked = false;
    public typesVehicle: GetTypesVehicleResponse[] = []
    public dataCreatedSectorKey : null | number = null
    public modalVisibleVehicle= false;
    public hoursNew: CreateSectorHourEntity[] = []
    public colors: string[] = [
        "0, 123, 255, 0.5",
        "40, 167, 69, 0.5",
        "255, 193, 7, 0.5",
        "255, 87, 34, 0.5",
        "255, 0, 0, 0.5",
        "156, 39, 176, 0.5",
        "0, 188, 212, 0.5",
        "244, 143, 177, 0.5",
        "158, 158, 158, 0.5",
        "64, 224, 208, 0.5"
    ];

    public headers: Header[] = [
        { text: "Nombre", value: "name" },
        { text: "Horas", value: "hours" },
        { text: "", value: "actions" },
    ];

    mounted(): void {
        this.getSectors()
        this.getTypesVehicle()
    }

    setSectorHour(sectorHour: Hour) {
        this.sectorHourSelected = sectorHour;
        this.isVisibleDetail = true
    }

    closeModalSectorHour() {
        this.sectorHourSelected = null;
        this.isVisibleDetail = false
    }

    getNameVehicle(id: string | null) {
        if (id == null) {
            return 'NN'
        }
        const typeVehicle = this.typesVehicle.find(({ _id }) => _id == id);
        if (typeVehicle) {
            return typeVehicle.name
        }
        return 'NN'
    }

    formatCurrencyS(value: string | number) {
        return formatCurrency(parseInt(value as string))
    }

    async getTypesVehicle() {
        try {
            const typesVehicle = await this.vehicleApi.getTypesVehicleAiport();
            this.typesVehicle = typesVehicle.map(({_id,name}) => {
                return {
                    _id,
                    name: `${name}`
                }
            })
        } catch (error) {
            getErrorCatch(error);
        }
    }

    async getSectors() {
        this.loadingTable = true;
        try {
            this.items = await this.apiSector.get()
        } catch (error) {
            getErrorCatch(error)
        }
        this.loadingTable = false;
    }

    toCoords(id:string){
        const sector = this.items.find(({ _id }) => _id == id)
        if (!sector) {
            throw new Error("Id no existe")
        }
        try {
            const url = `https://geojson.io/#data=data:application/json,${JSON.stringify(sector.structure)}`
            window.open(url, '_blank');
        } catch (error) {
            getErrorCatch(error)
        }
    }

    setEditSector(id: string) {
        const sector = this.items.find(({ _id }) => _id == id)
        if (!sector) {
            throw new Error("Id no existe")
        }
        this.sectorSelected = sector;
        this.isVisibleEditSector = true;
        this.$nextTick(() => {
            for (let index = 0; index < sector.hours.length; index++) {
                const element = sector.hours[index];
                this.sectorHour = `${element.hourMin} - ${element.hourFinish}`
                const uid = Math.random().toString(36).substring(2, 11);
                this.addRange(true,uid)
                this.hoursNew.push({
                    hourFinish: element.hourFinish,
                    hourMin: element.hourMin,
                    isBlocked: element.isBlocked,
                    vehicles: element.vehicles.map(({ duration, price, typeVehicle }) => {
                        return {
                            duration,
                            price,
                            typeVehicle: typeof typeVehicle == 'object' ? typeVehicle._id : ''
                        }
                    }),
                    uid
                })
                this.sectorHour = null
            }
        })
    }

    closeModalEditSector() {
        this.sectorSelected = null;
        this.isVisibleEditSector = false;
        this.hoursNew = [];
        this.resetFormAddHour();
        this.dataCreatedSectorKey= null
    }

    resetFormAddHour(){
        this.sectorHour = "";
        this.sectorHourTypeBlocked = false;
        this.sectorHourIsError = false;
        this.sectorMessageError = "";
    }

    isValid24HourTime(range: string): string | null {
        const timeRangeRegex = /^([01]\d|2[0-3]):([0-5]\d) - ([01]\d|2[0-3]):([0-5]\d)$/;
        const match = range.match(timeRangeRegex);
        if (!match) {
            return "El formato debe ser 'HH:mm - HH:mm' con horas en formato 24 horas.";
        }

        const startHour = parseInt(match[1], 10);
        const startMinute = parseInt(match[2], 10);
        const endHour = parseInt(match[3], 10);
        const endMinute = parseInt(match[4], 10);

        if (startHour > endHour || (startHour === endHour && startMinute >= endMinute)) {
            return "La hora de inicio debe ser menor que la hora final.";
        }

        return null;
    }

    addRange(withOutNewHour = false, uid= '') {
        const uidTemp = Math.random().toString(36).substring(2, 11);
        const uidFinal = uid && uid !== '' ? uid : uidTemp
        if (!this.sectorSelected) {
            return;
        }
        try {
            this.setDataHourSector(true)
            if (!this.sectorHour || this.sectorHour == '') {
                this.sectorHourIsError = true;
                this.sectorMessageError = "Campo requerido"
                return;
            }
            const isValidTempHour = this.isValid24HourTime(this.sectorHour);
            if (typeof isValidTempHour == 'string') {
                this.sectorHourIsError = true;
                this.sectorMessageError = isValidTempHour;
                return;
            }
            const [start, end] = this.sectorHour.trim().split("-").map((time) => time.trim());
            if (this.isOverlapping(start, end)) {
                alert("El rango ingresado se superpone con uno existente.")
                return;
            }
            const [startHours, startMinutes] = start.split(":").map(Number);
            const [endHours, endMinutes] = end.split(":").map(Number);

            const startDeg = this.calculateDegrees(startHours % 12, startMinutes);
            const endDeg = this.calculateDegrees(endHours % 12, endMinutes);

            const isAMStart = startHours < 12;
            const isAMEnd = endHours < 12;

            const refAm = this.$refs[`sectors-am`] as HTMLElement;
            const refPm = this.$refs[`sectors-pm`] as HTMLElement
            if (!refAm) {
                throw new Error('No se ha podido crear el sector, contacta con el admin.')
            }
            if (!refPm) {
                throw new Error('No se ha podido crear el sector, contacta con el admin.')
            }
            if(!withOutNewHour){
                this.hoursNew.push({
                    hourFinish: end,
                    hourMin: start,
                    isBlocked: this.sectorHourTypeBlocked || false,
                    vehicles: [],
                    uid: uidFinal
                })
                this.hoursNew.sort((a, b) => {
                    const timeA = DateTime.fromFormat(a.hourMin, 'HH:mm');
                    const timeB = DateTime.fromFormat(b.hourMin, 'HH:mm');
                    return timeA < timeB ? -1 : timeA > timeB ? 1 : 0;
                });
            }

            const color = this.sectorHourTypeBlocked ? '255, 0, 0, 1' : this.colors[this.hoursNew.length]

            if (isAMStart && isAMEnd) {
                this.createSector(refAm, startDeg, endDeg, color,uidFinal);
            } else if (!isAMStart && !isAMEnd) {
                this.createSector(refPm, startDeg, endDeg, color,uidFinal);
            } else {
                this.createSector(refAm, startDeg, 360, color,uidFinal); // Completa en AM
                this.createSector(refPm, 0, endDeg, color,uidFinal); // Inicia en PM
            }
            this.resetFormAddHour();
        } catch (error) {
            getErrorCatch(error)
        }
    }

    setDataHourSector(reset = false) {
        if (reset) {
            this.sectorMessageError = "";
            this.sectorHourIsError = false;
        }
    }

    calculateDegrees(hours: number, minutes: number) {
        const totalMinutes = hours * 60 + minutes;
        return (totalMinutes / (12 * 60)) * 360;
    }

    toMinutes(time: string) {
        const [hours, minutes] = time.split(":").map(Number);
        return hours * 60 + minutes;
    }
    isOverlapping(newStart: string, newEnd: string) {
        if (!this.sectorSelected) {
            return;
        }
        const newStartMinutes = this.toMinutes(newStart);
        const newEndMinutes = this.toMinutes(newEnd);
        return this.hoursNew.some((range) => {
            const rangeStartMinutes = this.toMinutes(range.hourMin);
            const rangeEndMinutes = this.toMinutes(range.hourFinish);
            return (
                (newStartMinutes >= rangeStartMinutes && newStartMinutes < rangeEndMinutes) ||
                (newEndMinutes > rangeStartMinutes && newEndMinutes <= rangeEndMinutes) ||
                (newStartMinutes <= rangeStartMinutes && newEndMinutes >= rangeEndMinutes)
            );
        }
        );
    }

    createSector(clock: HTMLElement, startDeg: number, endDeg: number, color = "0, 123, 255, 0.5",uid: string) {
        const sector = document.createElement("div");
        sector.id = `hour_${uid}`
        sector.style.position = "absolute";
        sector.style.width = "100%";
        sector.style.height = "100%";
        sector.style.borderRadius = "50%";
        sector.style.background = `conic-gradient(
            transparent ${startDeg}deg,
            rgba(${color}) ${startDeg}deg,
            rgba(${color}) ${endDeg}deg,
            transparent ${endDeg}deg
        )`;
        clock.appendChild(sector);
    }

    deleteHour(uidRemove: string) {
        try {
            this.hoursNew = this.hoursNew.filter(({uid}) => uid !== uidRemove); 
            const elements = document.querySelectorAll(`#hour_${uidRemove}`)
            if (elements.length > 0) {
                elements.forEach((element) => {
                    element.remove()
                })
            } else {
                throw new Error("Ocurrio un error al eliminar")
            }
        } catch (error) {
            getErrorCatch(error)
        }
    }

    addTypeVehicle(keyHour: number) {
        this.dataCreatedSectorKey = keyHour
        this.modalVisibleVehicle= true;
    }

    async addHourSector() {
        try {
            const form = this.$refs['addHourSector'] as FormVuetify
            if (!form) {
                return
            }
            const { valid } = await form.validate()
            if (valid && this.dataCreatedSectorKey !== null) {
                const vehicles = this.hoursNew[this.dataCreatedSectorKey].vehicles;
                if (vehicles.find(({ typeVehicle }) => typeVehicle == this.dataAddVehicle.typeVehicle)) {
                    throw new Error('Tipo de vehículo ya registrado')
                }
                this.hoursNew[this.dataCreatedSectorKey].vehicles.push({
                    duration: parseInt((this.dataAddVehicle.duration as string) || "0"),
                    price: parseInt((this.dataAddVehicle.price as string) || "0"),
                    typeVehicle: this.dataAddVehicle.typeVehicle
                })
                this.dataAddVehicle = {
                    duration: null,
                    price: null,
                    typeVehicle: null
                }
            }
        } catch (error) {
            this.dataAddVehicle = {
                duration: null,
                price: null,
                typeVehicle: null
            }
            getErrorCatch(error)
        }
    }
    deleteHourSector(keyVehicle: number, keyHour: number) {
        if (this.sectorSelected == null) {
            return;
        }
        this.hoursNew[keyHour].vehicles = this.hoursNew[keyHour].vehicles.filter((item, key) => key !== keyVehicle);
    }

    timeToMinutes(time: string) {
        const [hours, minutes] = time.split(":").map(Number);
        return hours * 60 + minutes;
    }
    minutesToTime(minutes: number) {
        const hours = Math.floor(minutes / 60).toString().padStart(2, "0");
        const mins = (minutes % 60).toString().padStart(2, "0");
        return `${hours}:${mins}`;
    }

    findMissingIntervals(timeIntervals: { hourMin: string, hourFinish: string }[]) {
        const fullDayStart = "00:00";
        const fullDayEnd = "23:59";
        const sortedIntervals = timeIntervals
            .map(interval => ({
                hourMin: this.timeToMinutes(interval.hourMin),
                hourFinish: this.timeToMinutes(interval.hourFinish),
            }))
            .sort((a, b) => a.hourMin - b.hourMin);
        const missingIntervals: { hourMin: string, hourFinish: string }[] = [];
        let currentTime = this.timeToMinutes(fullDayStart);
        for (const interval of sortedIntervals) {
            if (currentTime < interval.hourMin) {
                // Add missing interval
                missingIntervals.push({
                    hourMin: this.minutesToTime(currentTime),
                    hourFinish: this.minutesToTime(interval.hourMin - 1),
                });
            }
            currentTime = Math.max(currentTime, interval.hourFinish);
        }
        if (currentTime < this.timeToMinutes(fullDayEnd)) {
            missingIntervals.push({
                hourMin: this.minutesToTime(currentTime),
                hourFinish: this.minutesToTime(this.timeToMinutes(fullDayEnd)),
            });
        }

        return missingIntervals;
    }

    async saveSectors(){
        if(!this.sectorSelected){
            return;
        }
        this.isLoadingCreated = true;
        try {
            if (this.hoursNew.length == 0) {
                throw new Error('Sectores no tienen horas asignadas')
            }
            let errors = []
                const hoursElement = this.hoursNew.map((h) => ({ hourMin: h.hourMin, hourFinish: h.hourFinish }));
                const data = this.findMissingIntervals(hoursElement)
                if(data.length> 0){
                    errors.push(`Al sector ${this.sectorSelected.name} le falta los siguientes rangos horarios: ${data.map(({hourFinish,hourMin}) => `${hourMin} - ${hourFinish}`).join(", ")}`)
                }
                const vehiclesEmpty = this.hoursNew.filter(({isBlocked}) => !isBlocked).filter(({vehicles}) => vehicles.length == 0);
                if(vehiclesEmpty.length >0){
                    errors.push(`Al sector ${this.sectorSelected.name} le falta agregar vehículos a sus rangos horarios`)
                }
            
            if(errors.length > 0){
                throw new Error(errors.join(", "));
            }
            await this.apiSector.updateSector(this.sectorSelected._id,this.hoursNew);
            await this.getSectors()
            this.closeModalEditSector()
        } catch (error) {
            getErrorCatch(error)
        }
        this.isLoadingCreated = false;
    }
}
