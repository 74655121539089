/* eslint-disable */
import { DateTime } from "luxon";
import { useMainStore } from "../store/modules/main";
import { DAY } from "@/types/request/vehicle";

class ErrorResponse {
  constructor(public message: string, public statusCode: number) { }
}

export const validateEmail = (email: string) => {
  if (!email) {
    return true;
  }
  const emailRegex = new RegExp(
    /^[A-Za-z0-9_!#$%&'*+=?`{|}~^.-]+@[A-Za-z0-9.-]+$/,
    "gm"
  );
  if (emailRegex.test(email)) return true;
  return "Correo inválido";
};

export const repeatEmail = (email: string, emailSecond: string) => {
  if (email == emailSecond) return true;
  return "Correo es diferente";
};

export const max = (value: string, max = 1) => {
  try {
    const number = parseInt(value);
    if (isNaN(number)) {
      return "Número inválido";
    }
    if (number > max) {
      return `El número debe ser menor o igual a ${max}`;
    }
    return true;
  } catch (error) {
    return "Esté campo es inválido";
  }
};

export const min = (value: string | any, min = 0) => {
  try {
    const number = parseInt(value);
    if (isNaN(number)) {
      return "Número inválido";
    }
    if (number < min) {
      return `El número debe ser mayor o igual a ${min}`;
    }
    return true;
  } catch (error) {
    return "Esté campo es inválido";
  }
};

export const different = (
  value: string,
  otherInput: string,
  nameField: string
) => {
  if (value == otherInput) return "El campo es igual a " + nameField;
  return true;
};

export const requiredField = (field: string) => {
  return !!field || "Este campo es necesario";
};

export const ruleValidateRut = (rut: string) => {
  return !!validateRut(rut) || "Rut inválido";
};

export const maxItem = (rut: string, max: number) => {
  return rut.length <= max || `Solo se permite ${max} carácteres.`;
};

export const validateRut = (rut: string) => {
  rut = rut.replace(/\./g, "").replace(/-/g, "");
  if (rut.length < 5) {
    return false;
  }
  if (!/^[0-9]+[0-9kK]{1}$/.test(rut)) {
    return false;
  }
  const checkDigit = rut.charAt(rut.length - 1);
  const bodyRut = rut.substring(0, rut.length - 1);
  let suma = 0;
  let multiplo = 2;
  for (let i = bodyRut.length - 1; i >= 0; i--) {
    suma += parseInt(bodyRut.charAt(i)) * multiplo;
    if (multiplo < 7) {
      multiplo += 1;
    } else {
      multiplo = 2;
    }
  }
  const dvEsperado = 11 - (suma % 11);
  if (dvEsperado === 10) {
    return checkDigit.toUpperCase() === "K";
  } else if (dvEsperado === 11) {
    return checkDigit === "0";
  } else {
    return parseInt(checkDigit) === dvEsperado;
  }
};

//eslint-disable-next-line
export const getErrorCatch = (
  error: ErrorResponse | any,
  sendMessage = true
) => {
  const storeMain = useMainStore();
  let message = error.message || "Ocurrio un error";
  let errorCode = 401;
  if (error instanceof ErrorResponse) {
    message = error.message;
    errorCode = error.statusCode;
  }
  if (sendMessage) {
    storeMain.setNotification({
      isOpen: true,
      message,
      color: "red darken-4",
      timeout: 3000,
    });
  }
  return {
    message,
    errorCode,
  };
};

export function formatRutChile(rut: string): string {
  if (rut === "") return "";
  rut = rut.replace(/[^\dKk]/g, "");
  const dv = rut.slice(-1);
  const integerPart = rut.slice(0, -1);
  const formattedIntegerPart = parseInt(integerPart).toLocaleString("es-CL");
  return `${formattedIntegerPart}-${dv.toUpperCase()}`;
}

export function validateNumber(event: KeyboardEvent) {
  const allowedKeysPattern = /^[0-9Kk]+$/;
  if (!allowedKeysPattern.test(event.key)) {
    event.preventDefault();
  }
}

export function customScrollTo(sectionId: string, topOffset: number) {
  try {
    const sectionElement = document.getElementById(sectionId);
    console.log(sectionElement)
    if (!sectionElement) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return;
    }
    const { top } = sectionElement.getBoundingClientRect();
    const position = top + window.scrollY - topOffset;
    const scrollPosition = Math.max(0, position);
    window.scrollTo({
      top: scrollPosition < 200? 0 : scrollPosition,
      behavior: "smooth",
    });
  } catch (error) {
    console.log(error);
  }
}
export function formatCurrency(number: number) {
  return new Intl.NumberFormat("es-CL", {
    style: "currency",
    currency: "CLP",
  }).format(number);
}

export function getStatusFLOW(status: string) {
  const statusFlow: Record<string, string> = {
    "1": "Pendiente de pago",
    "2": "Pagada",
    "3": "Rechazada",
    "4": "Anulada",
  };
  return statusFlow[status] || "";
}

export function getHourMinutesFromString(hour: string){
  const hourSplit = hour.split(":");
  if(hourSplit.length == 0){
    throw new Error("Hora inválida")
  }
  return {
    hour : parseInt(hourSplit[0]),
    minutes : parseInt(hourSplit[1])
  }
}


export function addMinutesToTime(time: string, minutesToAdd: number): string {
  const timeObj = DateTime.fromFormat(time, "HH:mm");
  const updatedTime = timeObj.plus({ minutes: minutesToAdd });
  return updatedTime.toFormat("HH:mm");
}

export function getRole(role?: string) {
  const roles: Record<string, string> = {
    ADMIN: "Administrador",
    DRIVER: "Conductor",
    CUSTOMER: "Cliente",
  };
  return role ? roles[role] || "" : "";
}

export function getStatusColor(value: string): string {
  const colors: Record<string, string> = {
    ABSEND: "orange",
    ATTENDED: "green",
    POSTPONED: "red",
    ON_HOLD: "grey-darken-4",
  };
  return colors[value] || "";
}

export function translate(value: string): string {
  const data: Record<string, string> = {
    ATTENDED: "Presente",
    ABSEND: "Ausente",
    ON_HOLD: "En espera",
    POSTPONED: "Postergado",
    FINALIZED: "Finalizado",
  };
  return data[value] || "";
}

export function sleep(time = 1000, timeOut = 0): Promise<void> {
  return new Promise((res) => {
    timeOut = setInterval(() => {
      res();
    }, time);
  });
}

export const getStartNoonTimeDate = (timeDefault: Date) => {
  return DateTime.fromJSDate(timeDefault)
    .setZone("America/Santiago")
    .set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
    .toJSDate();
};

export function generateListDates(dateInit: Date, dateFinish: Date) {
  const dateNow = new Date(dateInit);
  const dateFinal = new Date(dateFinish);
  const fechas: Date[] = [];
  while (dateNow <= dateFinal) {
    fechas.push(getStartNoonTimeDate(dateNow));
    dateNow.setDate(dateNow.getDate() + 1);
  }
  return fechas;
}

export function getDayString(date: Date) {
  return date.toISOString().split("T")[0];
}

enum PatronFechas {
  SOLO_LUNES = "Días lunes",
  SOLO_MARTES = "Días martes",
  SOLO_MIERCOLES = "Días miércoles",
  SOLO_JUEVES = "Días jueves",
  SOLO_VIERNES = "Días viernes",
  SOLO_SABADO = "Días sábado",
  SOLO_DOMINGO = "Días domingo",
  DE_LUNES_A_VIERNES = "De lunes a viernes",
  SOLO_FIN_DE_SEMANA = "Fin de semana",
  OTRO_PATRON = "",
}

export function determinarPatronFechas(dates: Date[]): PatronFechas | string {
  const fechas = dates.map((date) => DateTime.fromJSDate(date));
  const diasSemana = fechas.map((fecha) => fecha.weekday);
  const unicos = new Set(diasSemana);

  if (unicos.size === 1) {
    switch (diasSemana[0]) {
      case 1:
        return PatronFechas.SOLO_LUNES;
      case 2:
        return PatronFechas.SOLO_MARTES;
      case 3:
        return PatronFechas.SOLO_MIERCOLES;
      case 4:
        return PatronFechas.SOLO_JUEVES;
      case 5:
        return PatronFechas.SOLO_VIERNES;
      case 6:
        return PatronFechas.SOLO_SABADO;
      case 7:
        return PatronFechas.SOLO_DOMINGO;
    }
  } else if (
    unicos.size === 5 &&
    !diasSemana.includes(6) &&
    !diasSemana.includes(7)
  ) {
    return PatronFechas.DE_LUNES_A_VIERNES;
  } else if (
    unicos.size === 2 &&
    diasSemana.includes(6) &&
    diasSemana.includes(7)
  ) {
    return PatronFechas.SOLO_FIN_DE_SEMANA;
  }

  return "";
}

export function everyDay(dates: Date[], weekday = 1): boolean {
  const datesFinal = dates.map((date) => DateTime.fromJSDate(date));
  return datesFinal.every((date) => date.weekday === weekday);
}

export function dateToDayMonth(date: number) {
  const newDate = DateTime.fromJSDate(new Date(date)).setLocale('es-CL')
  let month = newDate.toFormat('ccc');
  return {
    day: newDate.toFormat('dd'),
    month: month.charAt(0).toUpperCase() + month.slice(1)
  }
}

export function getDaysInRange(range: number[]) {
  try {
    const mainStore = useMainStore()
    const days: { day: string, month: string }[] = []
    for (let index = 0; index < range.length; index++) {
      const day = range[index];
      const newDate = DateTime.fromJSDate(new Date(day)).setZone(mainStore.timeZone).setLocale('es-CL')
      let month = newDate.toFormat('ccc');
      days.push({
        day: newDate.toFormat('dd'),
        month: month.charAt(0).toUpperCase() + month.slice(1)
      })
    }
    return days
  } catch (error) {
    console.log(error)
    return []
  }
}

export function MonToFri(dates: Date[]) {
  const datesFinal = dates.map((date) => DateTime.fromJSDate(date));
  return datesFinal.every((fecha) => fecha.weekday >= 1 && fecha.weekday <= 5);
}

export function translateTypeDistribution(typeDistribution: string) {
  const data: Record<string, string> = {
    DEFAULT: "Por defecto",
    DEFAULT_V2: "Nueva distribución",
    SEATING_16: "Bus 16 asientos",
  };
  return data[typeDistribution] || "";
}


export function getDayNameFromKey(key: DAY) {
  const daysMap: Record<DAY, string> = {
    "L": "Lunes",
    "M": "Martes",
    "X": "Miércoles",
    "J": "Jueves",
    "V": "Viernes",
    "S": "Sábado",
    "D": "Domingo",
  };

  return daysMap[key] || "Día inválido";
}