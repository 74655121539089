
import { useRoutesStore } from "@/store/modules/route";
import { usePlanningStore } from "@/store/modules/planning";
import { FormVuetify, SelectData } from "@/types/common/vuetify";
import { customScrollTo, getErrorCatch, requiredField, sleep } from "@/utils";
import { Vue, Options } from "vue-class-component";
import HeaderCommonBooking from "./HeaderCommonBooking.vue";
import BannerMobile from "@/assets/banner_airport.png";
import IconCalendar from "@/assets/images/icons/calendar-icon.svg";
import IconClock from "@/assets/images/icons/clock-icon.svg";
import IconPassengers from "@/assets/images/icons/passengers-icon.svg";
import { useDisplay } from "vuetify";
import { useAuthStore } from "@/store/modules/auth";
import { useMainStore } from "@/store/modules/main";
import { GetZonesResponse } from "@/types/response/route";
import { LocationsApi } from "@/api/locations";
import { SearchFormAirport, useAirportBookingStore } from "@/store/modules/airportBooking";
import { GetSearchAddress } from "@/types/response/locations";
import { DateTime } from "luxon";

interface SeatingsType {

  key: 'adults' | 'children' | 'babys',
  size: number,
  name: string,
  description: string
}

@Options({
  name: "FormSearchAirport",
  components: {
    HeaderCommonBooking
  },
  props: {
    home: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
})
export default class FormSearchAirport extends Vue {
  private locationsApi = new LocationsApi()
  private airportBookingStore = useAirportBookingStore()
  public routeStore = useRoutesStore();
  public planningStore = usePlanningStore();
  private mainStore = useMainStore();
  private authStore = useAuthStore();
  public requiredField = requiredField;
  public searchLoading = false;
  public valueTempSW = false
  public mobile = false;
  public home!: boolean;
  public fromSend = false;
  public zones: GetZonesResponse[] = []
  public zoneSelected: null | string = null
  public addressSearch = null;
  public address: GetSearchAddress[] = []
  public isLoading = false;
  public timeout = null;
  public hours: SelectData[] = []
  public menuOpenOrigin = false;
  public menuOpenDestination = false;
  public searchTextOrigin = "";
  public IconCalendar = IconCalendar;
  public IconClock = IconClock;
  public IconPassengers = IconPassengers;
  public seatings: SeatingsType[] = [
    {
      key: "adults",
      size: 0,
      name: "Adultos",
      description: ""
    },
    {
      key: "children",
      size: 0,
      name: "Niños",
      description: "0 a 8 años"
    }
  ]
  get countAdults() {
    return this.seatings.filter(({ key }) => key == 'adults').reduce((a, b) => a + b.size, 0);
  }

  get countChildrens() {
    return this.seatings.filter(({ key }) => key == 'children').reduce((a, b) => a + b.size, 0);
  }

  get minDate(){
    return DateTime.now().minus({hour: 1}).toJSDate()
  }

  get countBabys() {
    return this.seatings.filter(({ key }) => key == 'babys').reduce((a, b) => a + b.size, 0);
  }

  get step(){
    return this.airportBookingStore.step
  }

  public searchTextDestination = "";
  public listTypeTrip: SelectData[] = [
    {
      title: "Solo ida",
      value: "justReturn",
    }
  ];;

  public banner = BannerMobile;
  public timeOut = 0;

  get typeTripSelected() {
    return this.airportBookingStore.typeTripSelected
  }

  public dateCreateTrip: SearchFormAirport = {
    origin: undefined,
    destination: undefined,
    date: new Date(),
    typeTrip: "justReturn",
    dateFinal: new Date(),
    destinationText: '',
    bag: undefined,
    seatings: undefined,
    hour: undefined
  };

  get isMobile() {
    const rect = document.body.getBoundingClientRect();
    return rect.width - 1 < 572;
  }

  get today() {
    return DateTime.local().set({ hour: 0, minute: 0 }).toJSDate()
  }

  get disableDateFinal() {
    return !this.dateCreateTrip.date

  }

  get isLogged() {
    return this.authStore.isLogged;
  }

  get isSigned() {
    return this.authStore.user?.isSign;
  }

  get formData() {
    return this.airportBookingStore.form
  }

  get infoSeating(){
    return this.airportBookingStore.seatingsInfo
  }

  mounted(): void {
    const { mobile } = useDisplay();
    this.mobile = mobile.value;
    // rellenando datos por si existen
    const date = new Date();
    this.dateCreateTrip.date = date;
    this.getHours(this.dateCreateTrip.date,true)
    if (this.formData.bag) {
      this.dateCreateTrip.bag = this.formData.bag
    }
    if (this.formData.seatings) {
      this.dateCreateTrip.seatings = this.formData.seatings
    }
    if(this.formData.hour){
      this.dateCreateTrip.hour = this.formData.hour
    }
    if(this.formData.date){
      this.dateCreateTrip.date = this.formData.date
      if(this.formData.hour){
        const [hour, minute] = this.formData.hour.split(':').map(Number);
        const timeFinal = DateTime.fromJSDate(this.dateCreateTrip.date).set({ hour, minute, second: 0 }).setZone('America/Santiago', { keepLocalTime: true });
        this.dateCreateTrip.date = timeFinal.toJSDate()
      }
    }
    if(this.formData.destinationText){
      this.dateCreateTrip.destinationText = this.formData.destinationText
    }
    if(this.infoSeating){
      this.seatings = this.seatings.map((seating) => {
        if(seating.key == 'adults'){
          return {
            ...seating,
            size : this.infoSeating.adults
          }
        }else if(seating.key == 'babys'){
          return {
            ...seating,
            size : this.infoSeating.babys
          }
        }else if(seating.key == 'children'){
          return {
            ...seating,
            size : this.infoSeating.children
          }
        }
        return seating
      })
    }
    
  }

  onMenuUpdate(isOpen: boolean){
    console.log(document.querySelector('v-menu'))
    if(isOpen && this.dateCreateTrip.hour){
      this.$forceUpdate();
      this.$nextTick(() => {
          // Accedemos al componente interno v-menu a través de la referencia
          const menuAutocomplete = this.$refs['autocomplete'] as any;
          if(!menuAutocomplete){
            return;
          }
          console.log(document.querySelector('v-menu'))
          const menu = menuAutocomplete.$refs['menu'] as any;
          console.log(menu)
          if (menu) {
            // Buscamos todos los elementos de la lista que se muestran en el menú
            const listItems = menu.$el.querySelectorAll('.v-list-item');
            listItems.forEach((item: any) => {
              // Comparamos el texto del item con el valor seleccionado
              if (item.innerText.trim() === this.dateCreateTrip.hour) {
                // Hacemos scroll para centrar el elemento en la vista
                item.scrollIntoView({ behavior: 'smooth', block: 'center' });
              }
            });
          }
        });
    }
  }

  setHours(value: Date) {
    this.getHours(value)
  }

  selectAddress(item: GetSearchAddress, type: 'origin' | 'destination') {
    if (type === 'origin') {
      this.dateCreateTrip.destinationText = item.description;
      this.dateCreateTrip.destination = item.place_id;
      this.menuOpenOrigin = false;
    } else {
      this.dateCreateTrip.destinationText = item.description;
      this.dateCreateTrip.destination = item.place_id;
      this.menuOpenDestination = false;
    }
  }

  setPassengers(key: string, type: 'minus' | 'plus') {
    try {
      const seatingSelected = this.seatings.filter((seating) => seating.key == key);
      if (!seatingSelected) {
        throw new Error("No existe key")
      }
      this.seatings = this.seatings.map((seating) => {
        if (key == seating.key) {
          if (type == 'minus') {
            if (seating.size > 0) {
              return {
                ...seating,
                size: seating.size - 1
              }
            } else {
              throw new Error("Acción inválida, el número es 0")
            }
          } else {
            return {
              ...seating,
              size: seating.size + 1
            }
          }
        }
        return seating;
      })
      this.dateCreateTrip.seatings = this.countAdults + this.countBabys + this.countChildrens;
    } catch (error) {
      getErrorCatch(error)
    }
  }

  getHours(inputTime: Date,isInit = false) {
    this.hours = []
    const inputLuxon = DateTime.fromJSDate(inputTime);
    const todayLuxon = DateTime.now();
    let isToday = true;
    if (!inputLuxon.hasSame(todayLuxon, 'day')) {
      isToday = false
      inputTime.setHours(0, 0, 0, 0);
    }
    const timeSlots: SelectData[] = [];
    const currentTime = new Date(inputTime.getTime());
    const minutes = currentTime.getMinutes();
    const nextInterval = Math.ceil((minutes + 1) / 10) * 10;
    if (nextInterval === 60) {
      currentTime.setHours(currentTime.getHours() + 1);
      currentTime.setMinutes(0);
    } else {
      currentTime.setMinutes(nextInterval);
    }
    currentTime.setSeconds(0);

    const endTime = new Date(currentTime);
    endTime.setHours(23, 59, 0, 0);
    while (currentTime <= endTime) {
      const hours = currentTime.getHours();
      const mins = currentTime.getMinutes();
      const formattedTime = `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
      timeSlots.push({ title: formattedTime, value: formattedTime });
      currentTime.setMinutes(currentTime.getMinutes() + 10);
    }
    if (timeSlots.length === 1) {
      //this.dateCreateTrip.hour = timeSlots[0].value;
      //this.setHourInData(this.dateCreateTrip.hour);
      this.hours = timeSlots;
      return;
    }
    if(isInit){
      this.dateCreateTrip.hour = timeSlots[isToday ? 1 : 0].value;
      this.setHourInData(timeSlots[isToday ? 1 : 0].value);
    }else{
      if(this.dateCreateTrip.hour){
        this.setHourInData(this.dateCreateTrip.hour);
      }
      
    }
    
    this.hours = timeSlots.slice(isToday ? 1 : 0);
  }

  validateSign() {
    if (
      !this.authStore.isLogged) {
      this.mainStore.setNotification({
        isOpen: true,
        message:
          "Para continuar con la compra, debes iniciar sesión o crear tu cuenta.",
        isLink: true,
        link: "/login",
        nameLink: "Aquí",
        color: "red-darken-2",
        timeout: 3000,
        buttonClose: false,
      });
      return false;
    } else {
      if (!this.isSigned) {
        this.mainStore.setNotification({
          isOpen: true,
          message:
            "Para continuar con la compra, debes firmar tu contrato.",
          isLink: true,
          link: "/perfil",
          nameLink: "Aquí",
          color: "red-darken-2",
          timeout: 3000,
          buttonClose: false,
        });
        return false;
      }
    }
    return true;
  }

  async search() {
    const form = this.$refs["formSearch"] as FormVuetify;
    if (!form) return;
    const valid = await form.validate();
    this.fromSend = true;
    if (valid.valid) {
      if (!this.validateSign()) {
        return;
      }
      if(!this.dateCreateTrip.destinationText || !this.dateCreateTrip.hour){
        return;
      }
      this.searchLoading = true;
      try {
        const [hour, minute] = this.dateCreateTrip.hour.split(':').map(Number);
        const timeFinal = DateTime.fromJSDate(this.dateCreateTrip.date).set({ hour, minute, second: 0 }).setZone('America/Santiago', { keepLocalTime: true });
        this.airportBookingStore.setForm({...this.dateCreateTrip,date: timeFinal.toJSDate()});
        this.airportBookingStore.setSeatingsBabys({ adults: this.countAdults, babys: this.countBabys, children: this.countChildrens })
        const placesList = this.dateCreateTrip.destinationText.split(",");
        this.airportBookingStore.setDataAddress({addressText: this.dateCreateTrip.destinationText, commune: placesList.length >1 ? placesList[1].trim() : ""})
        await this.airportBookingStore.getZone()
        await sleep(300, this.timeOut);
        customScrollTo("list-vehicles-airport", 0);
      } catch (error) {
        getErrorCatch(error)
      }
      this.searchLoading = false;
    }
  }

  beforeUnmount(): void {
    clearInterval(this.timeOut);
  }

  different(value: string, valueCompare?: string) {
    if (!valueCompare) return true;
    return value !== valueCompare ? true : "Campo igual a origen";
  }
  back() {
    if (this.mobile) {
      this.planningStore.clearBooking();
      this.dateCreateTrip = {
        date: new Date(),
        destination: undefined,
        origin: undefined,
        dateFinal: new Date(),
        typeTrip: "round",
      };
      return;
    }
    this.$router.push("/");
  }

  setTypeTrip(value: 'fromAiport' | 'toAiport' | 'round') {
    this.airportBookingStore.setTypeTripSelected(value);
  }


  updateDateFinal(value: Date) {
    if (this.dateCreateTrip.typeTrip === "round") {
      this.dateCreateTrip.dateFinal = new Date(value);
    }
  }

  handleInput(type: 'origin' | 'destination') {
    const valueFinal = type === 'origin' ? this.dateCreateTrip.destinationText : this.dateCreateTrip.destinationText;
    clearTimeout(this.timeOut);
    if (valueFinal && valueFinal.length > 4) {
        this.$nextTick(() => {
          this.fetchAvenidas(valueFinal);
        });
      } else {
        this.address = [];
      }

  }

  async fetchAvenidas(search: string) {
    this.isLoading = true
    try {
      const response = await this.locationsApi.searchAddress(search);
      this.address = response.map((place) => {
        const listDescription = place.description.split(",");
        return {
          ...place,
          description : listDescription.length >2 ? listDescription.splice(0,2).join(",")  :place.description
        }
      })
    } catch (error) {
      if (!(error as any).isCancel) {
        getErrorCatch(error)
      }
    } finally {
      this.isLoading = false;
    }
  }

  setDestination(value: string) {
    const addressSelected = this.address.find(({ place_id }) => place_id == value);
    if (addressSelected) {
      this.dateCreateTrip.destinationText = addressSelected.description;
    }
  }

  setHourInData(value: string) {
    const [hour, minute] = value.split(':').map(Number);
    const date = DateTime.fromJSDate(this.dateCreateTrip.date).set({ hour, minute, second: 0 }).toJSDate();
    this.dateCreateTrip.date = date;
  }
}
