
import { VehicleAPI } from "@/api/vehicles";
import { CreateVehicleRequest } from "@/types/request/vehicle";
import {
  GetBrandsResponse,
  GetModelByBrandResponse,
  GetTypesVehicleResponse,
} from "@/types/response/vehicle";
import { getErrorCatch, requiredField, min, getDayNameFromKey } from "@/utils";
import { Options, Vue } from "vue-class-component";
import ModalCommon from "../common/ModalCommon.vue";
import { useVehicleStore } from "@/store/modules/vehicle";
import { FormVuetify } from "@/types/common/vuetify";
import { ResponseCommonName, ResponseCommonUsers } from "@/types/common/api";
import { UserApi } from "@/api/users";
import { DAY } from "@/types/request/vehicle";
import { DateTime } from "luxon";
@Options({
  name: "CreateVehicle",
  props: {
    isVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  components: {
    ModalCommon,
  },
})
export default class CreateVehicle extends Vue {
  public loadingCreateVehicle = false;
  private vehicleApi = new VehicleAPI();
  private userApi = new UserApi();
  private vehicleStore = useVehicleStore();
  public isVisible!: boolean;
  public driverSelected: string | null = null;
  public dataCreate: CreateVehicleRequest = {
    typeVehicle: undefined,
    patent: "",
    model: "",
    year: "",
    seatings: undefined,
    gpsID: "",
    typeDistribution: 'DEFAULT',
    bags: undefined,
    days: []
  };
  public typeCreated: 'airport' | 'default' = 'default'
  public brandSelected: string | undefined = "";
  public loadingModels = false;

  public brands: GetBrandsResponse[] = [];
  public models: GetModelByBrandResponse[] = [];
  public typesVehicle: GetTypesVehicleResponse[] = [];
  public drivers: ResponseCommonName[] = []
  public typeDistribution: ResponseCommonName[] = [
    {
      _id: "DEFAULT",
      name: "Por defecto",
    },
    {
      _id: "DEFAULT_V2",
      name: "Por defecto v2",
    },
    {
      _id: "SEATING_16",
      name: "Bus 16 asientos",
    },
  ];
  closeModalCreateVehicle() {
    this.dataCreate = {
      typeVehicle: "",
      gpsID: "",
      patent: "",
      model: "",
      year: "",
      seatings: "",
      typeDistribution: 'DEFAULT',
      days: []
    };
    this.brandSelected = "";
    const form = this.$refs["formCreateVehicle"] as FormVuetify;
    if (!form) return;
    form.reset();
    this.$emit("closeModal");
  }
  async saveVehicle() {
    const form = this.$refs["formCreateVehicle"] as FormVuetify;
    if (!form) return;
    const validate = await form.validate();
    if (validate.valid) {

      this.loadingCreateVehicle = true;
      try {
        if (this.driverSelected == null) {
          throw new Error("Conductor no seleccionado")
        }
        const data: CreateVehicleRequest = {
          ...this.dataCreate,
          year: parseInt(this.dataCreate.year as string),
          seatings: parseInt(this.dataCreate.seatings as string)
        };
        if (this.typeCreated == 'airport') {
          await this.vehicleApi.createVehicleAirport({ ...data, driver: this.driverSelected, bags: parseInt((this.dataCreate.bags as string) || "0") });
        } else {
          await this.vehicleApi.createVehicle(data);
        }
        //await this.vehicleStore.getVehicles();
        //this.closeModalCreateVehicle();
      } catch (error) {
        getErrorCatch(error);
      }
      this.loadingCreateVehicle = false;
    }
  }

  created() {
    this.$watch("isVisible", (value: boolean) => {
      if (value) {
        this.getBrands();
        this.getTypesVehicle();
        this.getDrivers()
      }
    });
  }

  async getDrivers() {
    try {
      const drivers = await this.userApi.getAllDrivers();
      this.drivers = drivers.map(({ _id, firstName, lastName }) => ({
        _id,
        name: `${firstName} ${lastName}`
      }))
    } catch (error) {
      getErrorCatch(error)
    }
  }

  async getBrands() {
    try {
      this.brands = await this.vehicleApi.getBrands();
    } catch (error) {
      getErrorCatch(error);
    }
  }

  async getTypesVehicle() {
    try {
      this.typesVehicle = await this.vehicleApi.getTypesVehicle();
    } catch (error) {
      getErrorCatch(error);
    }
  }

  async getModels(brand: string) {
    if (!brand) return;
    this.dataCreate.model = undefined;
    this.loadingModels = true;
    try {
      const models = await this.vehicleApi.getModelsByBrand(brand);
      this.models = models;
    } catch (error) {
      getErrorCatch(error);
    }
    this.loadingModels = false;
  }
  public requiredField = requiredField;
  public min = min;

  setTypeCreated(typeCreated: 'airport' | 'default') {
    this.typeCreated = typeCreated;
  }

  enableDisabledAddBlockedDay(dayEntry: DAY, enable = true) {
    this.dataCreate.days = this.dataCreate.days.map((day) => {
      if (day.day == dayEntry) {
        return {
          ...day,
          isAdd: enable,
          valueIsAdd: ''
        }
      }
      return day
    })
  }

  addBlockedDay(dayEntry: DAY, value?: string) {
    try {
      if (!value) {
        throw new Error("Valor vacío")
      }
      const formatoRegex = /^([01]\d|2[0-3]):([0-5]\d) - ([01]\d|2[0-3]):([0-5]\d)$/;
      if (!formatoRegex.test(value)) {
        const formatoRegexv2 = /^([01]\d|2[0-3]):([0-5]\d)-([01]\d|2[0-3]):([0-5]\d)$/;
        if(!formatoRegexv2.test(value)){
          throw new Error("El rango de horas es inválido. Debe tener el formato HH:mm - HH:mm.");
        }
      }

      const [hourMin, hourFinish] = value.replace(' - ', "-").split("-");
      const dtInicio = DateTime.fromFormat(hourMin, "HH:mm");
      const dtFin = DateTime.fromFormat(hourFinish, "HH:mm");

      if (dtInicio >= dtFin) {
        throw new Error("Hora inicial es mayor.")
      }

      const dayData = this.dataCreate.days.find((day) => day.day === dayEntry);
      if (dayData && dayData.hoursBlocked) {
        // Validar que el nuevo rango no colapse con los existentes
        const hayColision = dayData.hoursBlocked.some(({ hourMin: existingInit, hourFinish: existingFinish }) => {
          const dtExistingInit = DateTime.fromFormat(existingInit, "HH:mm");
          const dtExistingFin = DateTime.fromFormat(existingFinish, "HH:mm");

          return dtInicio < dtExistingFin && dtFin > dtExistingInit;
        });

        if (hayColision) {
          throw new Error("El rango de horas se superpone con uno existente.");
        }
      }

      this.dataCreate.days = this.dataCreate.days.map((day) => {
        if (day.day === dayEntry) {
          return {
            ...day,
            hoursBlocked: [
              ...day.hoursBlocked ? day.hoursBlocked : [],
              {
                hourMin,
                hourFinish
              }
            ],
            isAdd: false,
            valueIsAdd: ''
          };
        }
        return day;
      });
    } catch (error) {
      getErrorCatch(error)
    }

  }


  changeDays(dayEntry: DAY) {
    const exists = this.dataCreate.days.find(({ day }) => day == dayEntry)
    if (!exists) {
      this.dataCreate.days.push({
        day: dayEntry,
        dayExtend: getDayNameFromKey(dayEntry),
        hoursBlocked: [],
        valueIsAdd: ''
      })
    } else {
      this.dataCreate.days = this.dataCreate.days.filter(({ day }) => day !== dayEntry);
    }
  }

  deleteBlockDay(dayEntry: DAY, keyHour: number) {
    this.dataCreate.days = this.dataCreate.days.map((day) => {
      if (day.day === dayEntry) {
        return {
          ...day,
          hoursBlocked: day.hoursBlocked?.filter((_, index) => index !== keyHour) || []
        };
      }
      return day;
    });
  }
}
